<template>
    <div class="video-page">
        <div class="title-box">
            <div class="page-title">视频素材管理</div>
            <div class="btn" @click="showUpload = true">上传视频</div>
        </div>
        <div class="head">
            <div class="label">
                <span>视频套餐数据总览</span>
                <small class="exp-info">(套餐有效期至: {{ info.expired_at }})</small>
            </div>
            <div class="info-box">
                <div class="info-item">
                    <div class="circle" :style="{ '--progress': cdnProgress + '%', '--color': '#6670DF' }">
                        <div class="progress">{{ cdnProgress }}%</div>
                    </div>
                    <div class="info">
                        <div class="row">
                            <div class="name">流量使用量(GB)</div>
                            <!--                            <div class="pay cursor">-->
                            <!--                                <span>去购买</span>-->
                            <!--                                <img src="/assets/images/video/internet-traffic-pay-icon.png">-->
                            <!--                            </div>-->
                        </div>
                        <div class="row">
                            <div class="num">{{ info.cdn_flux_used }}</div>
                            <div class="total">购买流量总量{{ info.cdn_flux_size }}(GB)</div>
                        </div>
                    </div>
                </div>
                <div class="line"></div>
                <div class="info-item">
                    <div class="circle" :style="{ '--progress': storageProgress + '%', '--color': '#6DB14E' }">
                        <div class="progress">{{ storageProgress }}%</div>
                    </div>
                    <div class="info">
                        <div class="row">
                            <div class="name">储存使用量(GB)</div>
                            <!--                            <div class="pay cursor">-->
                            <!--                                <span>去购买</span>-->
                            <!--                                <img src="/assets/images/video/internet-traffic-pay-icon.png">-->
                            <!--                            </div>-->
                        </div>
                        <div class="row">
                            <div class="num">{{ info.storage_used }}</div>
                            <div class="total">购买储存总量{{ info.storage_size }}(GB)</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="page-line"></div>
        
        <a-spin type="loading" :spinning="spinning">
            <div class="container">
                <div class="video-item" v-for="item in videoList" :key="item.id">
                    
                    <div class="video-thumb" @click="playVideo(item)">
                        <img :src="item.thumb">
                        <div class="video-play-btn"></div>
                    </div>
                    
                    <div class="video-info">
                        
                        <div class="name">{{ item.name }}</div>
                        
                        <div class="info">
                            <div class="info-row">
                                <div class="info-label">视频时长</div>
                                <div class="info-num">{{ videoTime(item) }}</div>
                            </div>
                            <div class="info-row">
                                <div class="info-label">视频大小</div>
                                <div class="info-num">{{ videoSize(item) }}</div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="utils cursor">
                        <img src="/assets/images/video/del.png" @click="delVideo(item)">
                        <img src="/assets/images/video/edit.png" @click="gotoUpload(item)">
                    </div>
                </div>
            </div>
        </a-spin>
        
        <div class="upload-box" v-if="showUpload">
            <div class="content">
                <div class="header">
                    <div class="label">上传视频</div>
                    <div class="line"></div>
                    <div class="close cursor" @click="closeUpload">
                        <img src="/assets/images/video/upload-close.png">
                    </div>
                </div>
                <div class="upload">
                    
                    <div class="add-video add-video-progress" v-if="isUpload">
                        <div class="text">
                            <div>{{ uploadFile.name }}</div>
                            <div>{{ uploadTips }}</div>
                        </div>
                        <div class="progress">
                            <a-progress :percent="uploadPercent"/>
                        </div>
                        <div class="btn" @click="cancelUpload" v-if="uploadPercent < 100">取消上传</div>
                    </div>
                    
                    <a-upload name="file" accept=".mp4" :showUploadList="false"
                              :customRequest="customRequest" v-else>
                        <div class="add-video cursor">
                            <a-icon type="upload" style="font-size: 50px;color: #888;"/>
                            <div class="text">
                                <span>将视频拖拽到此处也可上传</span>
                                <div><small>仅支持上传H.264（AVC）编码的mp4文件</small></div>
                            </div>
                            <div class="btn">选择视频</div>
                        </div>
                    </a-upload>
                </div>
                <div class="codes-tool">
                    <a target="_blank" href="http://www.pcfreetime.com/formatfactory/CN/index.html">视频转码工具下载</a>
                    <a target="_blank" href="https://blog.51cto.com/u_16308706/8891080">视频转码教程</a>
                </div>
            </div>
        
        </div>
        
        <a-modal v-model="showVideoModal" :footer="null" width="800px" destroyOnClose>
            <template v-if="videoInfo">
                
                <div slot="title">{{ videoInfo.name }}</div>
                
                <video style="width: 100%" :src="videoInfo.url" autoplay controls></video>
            
            </template>
        </a-modal>
    
    </div>
</template>

<script>
import api from "@/repo/api";
import moment from "moment/moment";
import video from "@/utils/video";

const qiniu = require('qiniu-js');

export default {
    name: "Video",
    data() {
        return {
            spinning: false,
            showUpload: false,
            isUpload: false,
            uploadFile: null,
            uploadPercent: 0,
            current: 0,
            subscription: null,
            info: {},
            videoList: [],
            videoInfo: null,
            showVideoModal: false,
        }
    },
    mounted() {
        this.getInfo();
        this.getVideoList();
    },
    computed: {
        cdnProgress() {
            return ((this.info.cdn_flux_used / this.info.cdn_flux_size) * 100).toFixed(2)
        },
        storageProgress() {
            return ((this.info.storage_used / this.info.storage_size) * 100).toFixed(2)
        },
        uploadTips() {
            let fileSize = this.uploadFile.size / 1024 / 1024;
            return `视频大小：${fileSize.toFixed(2)}M`
        }
    },
    methods: {
        getInfo() {
            api.get('/ast-space/info', {}, res => {
                console.log(res);
                if (res.code == 0) {
                    let {cdn_flux_size, cdn_flux_used, storage_size, storage_used, expired_at} = res.data;
                    let info = {
                        cdn_flux_size: (cdn_flux_size / 1024 / 1024 / 1024).toFixed(0),
                        cdn_flux_used: (Math.abs(cdn_flux_used) / 1024 / 1024 / 1024).toFixed(2),
                        storage_size: (storage_size / 1024 / 1024 / 1024).toFixed(0),
                        storage_used: (Math.abs(storage_used) / 1024 / 1024 / 1024).toFixed(2),
                        expired_at
                    }
                    this.info = info;
                }
            })
        },
        getVideoList() {
            this.spinning = true;
            api.get('/ast-space/videos', {}, res => {
                console.log(res);
                this.spinning = false;
                if (res.code == 0) {
                    this.videoList = this.videoList.concat(res.data.data);
                }
            })
        },
        videoSize(item) {
            let size = Number(item.size) / 1024 / 1024;
            return size.toFixed(2) + 'M'
        },
        videoTime(item) {
            let seconds = Number(item.info.duration);
            var duration = moment.duration(Number(seconds), 'seconds');
            var h = duration.hours();
            var m = duration.minutes();
            var s = duration.seconds();
            return (h < 10 ? `0${h}` : h) + ':' + (m < 10 ? `0${m}` : m) + ':' + (s < 10 ? `0${s}` : s)
        },
        // 取消上传
        cancelUpload() {
            this.subscription.unsubscribe();
            this.isUpload = false;
            this.uploadFile = null;
            this.uploadPercent = 0;
        },
        playVideo(item) {
            this.videoInfo = item;
            this.showVideoModal = true;
        },
        closeUpload() {
            this.showUpload = false;
            this.isUpload = false;
            this.uploadFile = null;
            this.uploadPercent = 0;
        },
        customRequest(e) {
            const file = e.file;
            
            this.$loading.show();
            
            video.checkH264(file).then(() => {
                
                let size = Number(e.file.size) / 1024 / 1024;
                
                if (size > 5 && size < 1024) {
                    this.uploadFile = e.file;
                    this.isUpload = true;
                    this.getToken(e.file);
                } else {
                    if (size > 1024) {
                        this.$message.error('视频不能超过10GB');
                    }
                    if (size < 5) {
                        this.$message.error('视频不能小于5MB');
                    }
                }
            }).catch(e => {
                
                this.$message.error(e);
                
            }).finally(() => {
                
                this.$loading.hide();
                
            })
        },
        getToken(file) {
            let _this = this;
            api.post('/com/qi-niu-video-upload-token', {file_name: file.name, file_size: file.size}, res => {
                if (res.code == 0) {
                    let token = res.data.token;
                    let key = res.data.key;
                    
                    const config = {
                        chunkSize: 4,
                        useCdnDomain: true,
                        region: qiniu.region.z2
                    };
                    
                    const observable = qiniu.upload(file, key, token, config);
                    
                    var observer = {
                        next(res) {
                            let percent = Number(res.total.percent.toFixed(2));
                            _this.uploadPercent = percent;
                        },
                        error(err) {
                            console.log(111, err);
                        },
                        complete(res) {
                            console.log('complete', res);
                            if (res.code == 0) {
                                // 跳转
                                _this.$router.push({
                                    path: '/ast/serve/video/upload',
                                    query: res.data
                                })
                            }
                        },
                    };
                    
                    _this.subscription = observable.subscribe(observer) // 上传开始
                    console.log('subscription', _this.subscription);
                } else {
                    _this.$message.error(res.msg);
                    this.isUpload = false;
                    this.uploadFile = null;
                    this.uploadPercent = 0;
                }
            })
        },
        delVideo(item) {
            console.log(item);
            
            this.$message.error('开发中!');
            // let content = '该视频暂时还未在课程中使用，确认删除后该视频将永久消失！';
            // this.confirm(content).then(() => {
            
            //     api.post('/ast-space/delete-file', {id:item.id}, (res) => {
            
            //         if(res.code == 0){
            //             this.$message.error('删除成功!');
            //             this.getVideoList();
            //         }else{
            //             this.$message.error(res.msg);
            //         }
            
            //     })
            
            // })
        },
        confirm(content) {
            return new Promise((resolve) => {
                this.$confirm({
                    title: '提示',
                    content,
                    onOk() {
                        resolve()
                    },
                });
            })
        },
        gotoUpload(item) {
            console.log(item);
            
            this.$router.push({
                path: '/ast/serve/video/upload',
                query: item
            })
        }
    }
}
</script>

<style lang="less" scoped>
.upload-box {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    
    .content {
        width: 408px;
        background: #FFFFFF;
        border-radius: 2px;
        padding: 24px;
        position: relative;
        
        .header {
            .close {
                width: 56px;
                height: 56px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                right: 0;
                
                img {
                    width: 16px;
                    height: 16px;
                }
            }
            
            .label {
                color: #333333;
                font-size: 16px;
                font-weight: bold;
                line-height: 22px;
            }
            
            .line {
                width: 360px;
                height: 1px;
                background: #EEEEEE;
                margin: 16px 0;
            }
        }
        
        .upload {
            width: 360px;
            height: 300px;
            
            .add-video {
                width: 360px;
                height: 300px;
                border-radius: 8px;
                border: 1px dashed #999999;
                display: flex;
                flex-direction: column;
                justify-content: center;
                
                .progress {
                    width: 80%;
                    margin: 32px auto 0;
                }
                
                .text {
                    color: #333333;
                    font-size: 14px;
                    margin-top: 16px;
                    text-align: center;
                }
                
                .btn {
                    width: fit-content;
                    margin: 64px auto 0;
                }
                
                &:hover {
                    border-color: #1890FF;
                }
            }
            
            .add-video-progress {
                &:hover {
                    border: 1px dashed #999999;
                }
            }
        }
        
    }
}

.title-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    
    .page-title {
        font-weight: bold;
        margin-bottom: 0;
    }
}

.page-line {
    width: 100%;
    height: 1px;
    background: #EEEEEE;
    margin-top: 32px;
    margin-bottom: 24px;
}

.container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
    
    
    .video-item {
        width: 468px;
        height: 128px;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        margin-bottom: 24px;
        padding: 16px;
        display: flex;
        align-items: center;
        position: relative;
        
        .utils {
            position: absolute;
            top: 16px;
            right: 16px;
            
            img {
                width: 24px;
                height: 24px;
                
                &:nth-child(1) {
                    margin-right: 8px;
                }
            }
        }
        
        .video-thumb {
            width: 96px;
            height: 96px;
            margin-right: 16px;
            position: relative;
            
            img {
                width: 96px;
                height: 96px;
                object-fit: cover;
                border: 1px solid #EEEEEE;
            }
        }
        
        .video-info {
            height: 96px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            
            .name {
                color: #333333;
                font-size: 14px;
                font-weight: bold;
                line-height: 20px;
            }
            
            .info {
                display: flex;
                
                .info-row {
                    margin-right: 32px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    
                    .info-label {
                        color: #999999;
                        font-size: 14px;
                        line-height: 20px;
                    }
                    
                    .info-num {
                        color: #333333;
                        font-size: 16px;
                        font-weight: bold;
                        line-height: 22px;
                        margin-top: 8px;
                    }
                }
            }
            
            
        }
    }
}

.head {
    width: 100%;
    border: 1px solid #EEEEEE;
    
    .label {
        width: 100%;
        height: 54px;
        line-height: 54px;
        border-bottom: 1px solid #EEEEEE;
        color: #333333;
        font-size: 16px;
        font-weight: bold;
        padding: 0 24px;
    }
    
    .info-box {
        width: 100%;
        height: 98px;
        display: flex;
        align-items: center;
        
        .info-item {
            flex: 1;
            height: 100%;
            display: flex;
            align-items: center;
            padding: 0 24px;
            
            .circle {
                width: 64px;
                height: 64px;
                border-radius: 50%;
                background: conic-gradient(var(--color) 0%, var(--color) var(--progress), rgba(0, 0, 0, 0.04) var(--progress), rgba(0, 0, 0, 0.04) 100%);
                margin-right: 16px;
                position: relative;
                
                .progress {
                    width: 48px;
                    height: 48px;
                    line-height: 48px;
                    border-radius: 50%;
                    text-align: center;
                    background: #fff;
                    position: absolute;
                    top: 8px;
                    left: 8px;
                    color: #333333;
                    font-size: 12px;
                }
            }
            
            .info {
                flex: 1;
                height: 64px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                
                .row {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    
                    .name {
                        color: #333333;
                        font-size: 14px;
                        line-height: 20px;
                    }
                    
                    .pay {
                        display: flex;
                        align-items: center;
                        
                        span {
                            color: #FBB03B;
                            font-size: 14px;
                            margin-right: 4px;
                            line-height: 20px;
                        }
                        
                        img {
                            width: 12px;
                            height: 12px;
                        }
                    }
                    
                    .num {
                        color: #333333;
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 24px;
                    }
                    
                    .total {
                        color: #999999;
                        font-size: 14px;
                        line-height: 24px;
                    }
                }
            }
        }
        
        .line {
            width: 1px;
            height: 50px;
            background: #EEEEEE;
            margin: 0 40px;
        }
    }
}

.codes-tool {
    padding-top: 10px;
    display: flex;
    justify-content: space-around;
    font-size: 12px;
}

.exp-info {
    font-weight: normal;
    margin-left: 16px;
    opacity: .8;
}

.video-play-btn {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.4);
    background-image: url("/assets/icons/video-play-btn.png");
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}
</style>